// @FIXME take action now link?
// @FIXME start consult link
// @FIXME Product image

import { useContext } from "react";
import { connect } from "react-redux";

import { logout } from "../src/redux/action/auth";
import Layout from "../components/includes/Layout";
import Header from "../components/includes/Header";
import CategoryList from "../components/home/CategoryList";
import ProList from "../components/home/ProList";
import CategoryContent from "../components/home/CategoryContent";
import HowItWork from "../components/home/HowItWork";
import MedicalTeam from "../components/home/MedicalTeam";
import ProductList from "../components/home/ProductList";
import Testimonial from "../components/home/Testimonial";
import Partners from "../components/home/Partners";
import ReturningUserJourney from "../components/home/ReturningUserJourney";

import { I18nContext } from "../components/I18N";
import AnimateTyping from "components/home/AnimateTyping";
import Link from "next/link";

import Modal from "react-modal";
import { setModal } from "src/redux/action/modal";
import get from "lodash/get";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    background: "#00000060",
    transform: "translate(-50%, -50%)",
    padding: 0,
    zIndex: 99,
  },
};

Modal.setAppElement("#__next");

function Home({ setModal, modal }) {
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);
  return (
    <>
      <div className="w-full font-[CentraNo2] bg-[#060606] text-[14px] py-[9px] row-mobile-col row-center space-x-[8px]">
        {/* <p className="text-[#61E3D4]">LIMITED OFFER:</p> */}
        <p className="text-[#fff]">{I18n.get("homePageBanner")}</p>
      </div>
      <Layout header headerFullwidth menuOffset={true}>
        <div className="mt-12 h-[753px] relative overflow-x-hidden  lg:justify-between">
          <div className="mx-auto h-auto h-[325px] lg:h-[336px]">
            <div
              className={`font-black text-[24px] leading-[40px] lg:text-[32px] lg:leading-[56px] ${
                langCode === "en" && "font-[CentraNo2]"
              }`}
            >
              <h2
                className="text-center text-[24px] lg:text-[32px]"
                dangerouslySetInnerHTML={{
                  __html: I18n.get("homepageMencare"),
                }}
              />
            </div>
            <div
              className={`text-center font-black lg:mt-[17px] text-[48px] leading-[40px] lg:text-[48px] lg:leading-[56px] ${
                langCode === "en" && "font-[CentraNo2]"
              }`}
            >
              <AnimateTyping
                sequence={I18n.get("homepageProblemList").split("<br />")}
              />
              {/* <p dangerouslySetInnerHTML={{ __html:  }} /> */}
            </div>
            <div className="mb-2 lg:space-x-0 mt-[25px] lg:mt-[23px]">
              <div className="flex text-[18px] leading-[24px] lg:text-[18px] lg:leading-[28px]">
                <h3
                  className="mx-auto text-center w-[720px] text-[18px] font-normal"
                  dangerouslySetInnerHTML={{
                    __html: I18n.get("homepageTalkToRealMD"),
                  }}
                />
              </div>
              <div className="flex w-full">
                <Link href="/treatment">
                  <div className="mx-auto  round-rectangle bg-[#060606] text-white">
                    <div
                      className="text-center"
                      dangerouslySetInnerHTML={{
                        __html: I18n.get("findMyTreatment"),
                      }}
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <CategoryList />
        </div>
        <ProList />
        <div
          className="container-wrapper small text-[#474747] pb-[25px] lg:pb-[30px] pt-[10px] lg:pt-[24px]"
          dangerouslySetInnerHTML={{
            __html: I18n.get("homeSubtitle"),
          }}
        />
        <CategoryContent />
        <HowItWork />
        <MedicalTeam />
        <ProductList />
        <Testimonial />
        <Partners />
        <div className="w-full h-[360px] lg:h-[520px] bg-[url('/images/homepage/ARM_WRESTLE.gif')] bg-center bg-cover bg-no-repeat flex-col row-center text-white font-extrabold">
          <p
            className={`text-center ${langCode === "en" && "font-[CentraNo2]"}`}
            dangerouslySetInnerHTML={{ __html: I18n.get("homePageActionNow") }}
          />
          <Link href="/treatment">
            <button className="big light">
              <p>{I18n.get("startConsult")}</p>
            </button>
          </Link>
        </div>
        <Modal
          isOpen={get(modal.content, "name", "") !== ""}
          style={customStyles}
          overlayClassName="modal-overlay"
        >
          <div className="z-50 w-screen h-screen flex items-center justify-center">
            <div
              className={`${langCode}-font w-[80%] md:w-[600px] rounded-[16px] p-[36px] mx-auto flex flex-col text-thai bg-white`}
            >
              <div className="flex justify-between">
                <p className="text-[24px] font-semibold">
                  {langCode === "en" &&
                  get(modal.content, "nameEN", false) &&
                  get(modal.content, "nameEN", false) !== ""
                    ? get(modal.content, "nameEN", "")
                    : get(modal.content, "name", "")}
                </p>
                <img
                  src="/images/close.png"
                  onClick={() => {
                    setModal(false);
                  }}
                  className="cursor-pointer w-[32px] h-[32px]"
                />
              </div>
              <div
                className="mt-[16px] text-[14px] max-h-[60vh] overflow-auto"
                dangerouslySetInnerHTML={{
                  __html:
                    langCode === "en" &&
                    get(modal.content, "safetyGuideEN", false) &&
                    get(modal.content, "safetyGuideEN", false) !== ""
                      ? get(modal.content, "safetyGuideEN", "")
                      : get(modal.content, "safetyGuide", ""),
                }}
              />
            </div>
          </div>
        </Modal>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  modal: state.modal,
});

export default connect(mapStateToProps, { logout, setModal })(Home);
