import LeftArrow from "components/commons/LeftArrow";
import RightArrow from "components/commons/RightArrow";
import { I18nContext } from "components/I18N";
import Link from "next/link";
import { useState, useEffect, useContext } from "react";

function DoctorList() {
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (active === 0) {
      document.getElementById("team-scroll").scrollLeft = 0;
    } else {
      document.getElementById("team-scroll").scrollLeft =
        document.getElementById("team-item-2").offsetLeft - 17;
    }
  }, [active]);

  useEffect(() => {
    setActive(0);
  }, []);

  return (
    <div
      className={`md:px-[25px] lg:px-0 flex-1 pt-0 pb-[45px] lg:py-0 bg-[#F7F5F1] lg:bg-white ${langCode}-font relative overflow-x-hidden`}
    >
      <div className="hidden lg:block w-full h-full absolute lg:pt-[58px]">
        <div className="w-full h-full bg-[#F7F5F1]" />
      </div>
      <div
        id="team-scroll"
        className="h-full flex space-x-[40px] lg:space-x-[80px] items-center overflow-x-auto lg:px-[65px]"
      >
        <div
          id="team-item-0"
          className="flex-none md:flex-1 lg:flex-none xl:flex-1 lg:w-[240px] h-full pb-[20px] lg:pb-0 lg:pt-[58px] relative"
        >
          <div className="h-full flex flex-col justify-center">
            <img
              src="/images/homepage/doctor1.webp"
              className="w-[200px] h-auto mx-auto"
            />
            {I18n.get("homePageMD1").split("<br />")[0] && (
              <h4 className="text-center title font-bold">
                {I18n.get("homePageMD1").split("<br />")[0]}
              </h4>
            )}
            {I18n.get("homePageMD1").split("<br />")[1] && (
              <h5 className="text-center subtitle2 font-bold">
                {I18n.get("homePageMD1").split("<br />")[1]}
              </h5>
            )}
            <h6
              className="text-center subtitle2"
              dangerouslySetInnerHTML={{ __html: I18n.get("homePageMD1Desc") }}
            />
          </div>
        </div>
        <div
          id="team-item-1"
          className="flex-none md:flex-1 lg:flex-none xl:flex-1 lg:w-[240px] h-full pb-[20px] lg:pb-0 lg:pt-[58px] relative"
        >
          <div className="h-full flex flex-col justify-center">
            <img
              src="/images/homepage/doctor2.webp"
              className="w-[200px] h-auto mx-auto"
            />
            {I18n.get("homePageMD2").split("<br />")[0] && (
              <h4 className="text-center title font-bold">
                {I18n.get("homePageMD2").split("<br />")[0]}
              </h4>
            )}
            {I18n.get("homePageMD2").split("<br />")[1] && (
              <h5 className="text-center subtitle2 font-bold">
                {I18n.get("homePageMD2").split("<br />")[1]}
              </h5>
            )}
            <h6
              className="text-center subtitle2"
              dangerouslySetInnerHTML={{ __html: I18n.get("homePageMD2Desc") }}
            />
          </div>
        </div>
        <div
          id="team-item-2"
          className="flex-none md:flex-1 lg:flex-none xl:flex-1 lg:w-[240px] h-full pb-[20px] lg:pb-0 lg:pt-[58px] relative"
        >
          <div className="h-full flex flex-col justify-center">
            <img
              src="/images/homepage/doctor3.webp"
              className="w-[200px] h-auto mx-auto"
            />
            {I18n.get("homePageMD3").split("<br />")[0] && (
              <h4 className="text-center title font-bold">
                {I18n.get("homePageMD3").split("<br />")[0]}
              </h4>
            )}
            {I18n.get("homePageMD3").split("<br />")[1] && (
              <h5 className="text-center subtitle2 font-bold">
                {I18n.get("homePageMD3").split("<br />")[1]}
              </h5>
            )}
            <p
              className="text-center subtitle2 max-w-[60vw]"
              dangerouslySetInnerHTML={{ __html: I18n.get("homePageMD3Desc") }}
            />
          </div>
        </div>
      </div>
      <div className="flex xl:hidden space-x-[16px] lg:space-x-[32px] absolute right-[20px] bottom-[10px] lg:right-[100px] lg:bottom-[48px] z-50">
        <LeftArrow
          onClick={() => {
            if (active > 0) {
              setActive(active - 1);
            }
          }}
        />
        <RightArrow
          onClick={() => {
            if (active < 2) {
              setActive(active + 1);
            }
          }}
        />
      </div>
    </div>
  );
}

function MedicalTeam() {
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);

  return (
    <div className="row-mobile-col">
      <div className="flex flex-col w-full lg:w-[475px] bg-[#C0DEF8] flex-none container-wrapper py-[48px] lg:py-[100px] lg:mt-[58px]">
        <h2
          className="heading6 font-black"
          dangerouslySetInnerHTML={{ __html: I18n.get("homePageHibroMDTeam") }}
        />
        <h4
          className="title mt-[16px] lg:my-[24px]"
          dangerouslySetInnerHTML={{
            __html: I18n.get("homePageHibroMDTeamDesc"),
          }}
        />
        <Link href="/medical-team">
          <div className="cursor-pointer flex-auto flex items-center space-x-[4px] justify-self-end mt-[38px]">
            <h3
              className="title font-semibold"
              dangerouslySetInnerHTML={{
                __html: I18n.get("homePageHibroMDTeamMeet"),
              }}
            />
            <img
              src="/images/include/right-black.png"
              className="h-[12px] w-auto"
            />
          </div>
        </Link>
      </div>

      <DoctorList />
    </div>
  );
}

export default MedicalTeam;
