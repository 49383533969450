import { API, graphqlOperation } from "aws-amplify";
import { searchOrders } from "../src/graphql/queries";
import get from "lodash/get";
import map from "lodash/map";
import { useState, useContext, useEffect } from "react";

export const useGetPhoneOrders = (auth) => {
  const getPhoneOrders = async (limitItems) => {
    const result = await API.graphql(
      graphqlOperation(searchOrders, {
        sort: { direction: "desc", field: "updatedAt" },
        limit: limitItems,
        filter: {
          categoryID: { eq: process.env.NEXT_PUBLIC_ENV_CAT_PHONE },
          userID: { eq: get(auth, "user.email", "") },
          and: [
            { status: { ne: "CANCELED" } },
            { status: { ne: "DOCTOR_REJECTED" } },
          ],
        },
      })
    );

    const items = get(result, "data.searchOrders.items", []) ?? [];

    let phoneList = [];

    for (let i = 0; i < items.length; i++) {
      const order = items[i];

      const searchResult = await API.graphql(
        graphqlOperation(searchOrders, {
          filter: {
            relateOrderID: { eq: order.id },
          },
        })
      );

      if ((get(searchResult, "data.searchOrders.items", []) ?? []).length > 0) {
        items.splice(i, 1);
      }
    }

    if (items.length > 0)
      phoneList = map(items, (order) => ({
        ...order,
        note: JSON.parse(order.note),
      }));

    return [...phoneList];
  };

  const getEDPhoneOrders = (phoneOrdersList) => {
    const edPhoneOrders = phoneOrdersList?.filter(
      (order) => order?.note?.categoryName === "ed"
    );

    return edPhoneOrders;
  };

  const getPEPhoneOrders = (phoneOrdersList) => {
    const pePhoneOrders = phoneOrdersList?.filter(
      (order) => order?.note?.categoryName !== "ed"
    );

    return pePhoneOrders;
  };

  return {
    getPhoneOrders,
    getEDPhoneOrders,
    getPEPhoneOrders,
  };
};
