function Partners() {
  return (
    <>
      <img
        onClick={() => window.open("https://www.asseenontvlive.com/")}
        src="/images/homepage/partner1.png"
        className="h-[18px] lg:h-[24px] w-auto object-contain m-auto lg:hidden mt-[34px]"
      />
      <div className="container px-[40px] py-[48px] lg:py-[98px] mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 grid-flow-row gap-[20px] lg:gap-[30px]">
        <img
          onClick={() => window.open("https://www.asseenontvlive.com/")}
          src="/images/homepage/partner1.png"
          className="h-[20px] lg:h-[24px] w-auto object-contain m-auto hidden lg:block"
        />
        <img
          onClick={() => window.open("https://www.techtalkthai.com/")}
          src="/images/homepage/partner2.png"
          className="h-[48px] lg:h-[54px] w-auto object-contain mx-auto cursor-pointer"
        />
        <img
          onClick={() => window.open("https://www.gqthailand.com/")}
          src="/images/homepage/partner3.png"
          className="h-[48px] lg:h-[54px] w-auto object-contain mx-auto cursor-pointer"
        />
        <img
          onClick={() => window.open("https://www.unlockmen.com/")}
          src="/images/homepage/partner4.png"
          className="h-[48px] lg:h-[54px] w-auto object-contain mx-auto cursor-pointer"
        />
        <img
          onClick={() => window.open("https://www.plasticsystems.it/pet/#")}
          src="/images/homepage/partner5.png"
          className="h-[48px] lg:h-[54px] w-auto object-contain mx-auto cursor-pointer"
        />
        <img
          onClick={() => window.open("https://nanake555.com/")}
          src="/images/homepage/partner6.png"
          className="hidden md:block h-[48px] lg:h-[54px] w-auto object-contain mx-auto cursor-pointer"
        />
      </div>
      <img
        onClick={() => window.open("https://nanake555.com/")}
        src="/images/homepage/partner6.png"
        className="block md:hidden h-[48px] lg:h-[54px] w-auto object-contain mx-auto cursor-pointer mb-[30px]"
      />
    </>
  );
}

export default Partners;
