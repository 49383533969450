import { I18nContext } from "components/I18N";
import { useContext } from "react";
import Link from "next/link";

function HowItWork() {
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);
  return (
    <div className="container-wrapper my-[80px] lg:my-[160px]">
      <h3
        className="heading6 font-black text-center"
        dangerouslySetInnerHTML={{ __html: I18n.get("homePageHowItWork") }}
      />
      <div className="row-mobile-col space-y-[32px] lg:space-y-0 lg:space-x-[32px] mt-[32px] lg:mt-[48px]">
        <div className="flex-1">
          <img
            alt="how it work"
            src="/images/homepage/How it works-01.webp"
            className="w-full h-[380px] object-cover mb-[16px] lg:mb-[24px] rounded-[12px]"
          />
          <h4
            className="text-[20px] leading-[28px] font-bold"
            dangerouslySetInnerHTML={{ __html: I18n.get("homePageHowItWork1") }}
          />
          <h5
            className="text-[16px] leading-[24px] mt-[8px]"
            dangerouslySetInnerHTML={{
              __html: I18n.get("homePageHowItWork1Desc"),
            }}
          />
        </div>
        <div className="flex-1">
          <img
            alt="how it work"
            src="/images/homepage/How it works-02.webp"
            className="w-full h-[380px] object-cover mb-[16px] lg:mb-[24px] rounded-[12px]"
          />
          <h4
            className="text-[20px] leading-[28px] font-bold"
            dangerouslySetInnerHTML={{ __html: I18n.get("homePageHowItWork2") }}
          />
          <h5
            className="text-[16px] leading-[24px] mt-[8px]"
            dangerouslySetInnerHTML={{
              __html: I18n.get("homePageHowItWork2Desc"),
            }}
          />
        </div>
        <div className="flex-1">
          <img
            alt="how it work"
            src="/images/homepage/How it works-03.webp"
            className="w-full h-[380px] object-cover mb-[16px] lg:mb-[24px] rounded-[12px]"
          />
          <h4
            className="text-[20px] leading-[28px] font-bold"
            dangerouslySetInnerHTML={{ __html: I18n.get("homePageHowItWork3") }}
          />
          <h5
            className="text-[16px] leading-[24px] mt-[8px]"
            dangerouslySetInnerHTML={{
              __html: I18n.get("homePageHowItWork3Desc"),
            }}
          />
        </div>
      </div>
      <div className="row-mobile-col lg:justify-center title mt-[32px] lg:mt-[80px] lg:space-x-[6px]">
        <p>{I18n.get("homePageGotQuestion")}</p>
        <Link href="/help-center">
          <div className="row space-x-[4px] text-[#3E92F2] cursor-pointer">
            <p className="underline">{I18n.get("homePageHelpCenter")}</p>
            <img
              alt="how it work"
              src="/images/include/right-blue.png"
              className="h-[12px] w-auto"
            />
          </div>
        </Link>
      </div>
    </div>
  );
}

export default HowItWork;
