import { useEffect, useRef, useState } from "react";

let interval;
const TYPE_SPEED = 50;
const PAUSE_TIME = 1500;

const Cursor = () => {
  return (
    <>
      <style>
        {`.blink {
          width: 0.3rem;
          background: black;
          animation: blink 1s step-start 0s infinite;
        }
        @keyframes blink {
          50% {
            opacity: 0.0;
          }
        }
        `}
      </style>
      <span className="blink">|</span>
    </>
  );
};

const AnimateTyping = ({ sequence = ["test", "123", "qweqwe qweqwe"] }) => {
  const direction = useRef("R");
  const isPause = useRef(false);
  const currentIndex = useRef(0);
  const [renderedText, setRenderedText] = useState("");

  useEffect(() => {
    interval = setInterval(() => {
      if (isPause.current) return;

      const _foucsText = sequence[currentIndex.current];

      let _nextTxt = "";

      if (direction.current === "R") {
        if (renderedText.length === _foucsText.length) {
          direction.current = "L";
          _nextTxt = renderedText;
          isPause.current = true;
          setTimeout(() => {
            isPause.current = false;
          }, PAUSE_TIME);
        } else {
          _nextTxt = _foucsText.slice(0, renderedText.length + 1);
        }
      } else if (direction.current === "L") {
        if (renderedText.length === 0) {
          direction.current = "R";
          currentIndex.current =
            currentIndex.current === sequence.length - 1
              ? 0
              : currentIndex.current + 1;
        } else {
          _nextTxt = _foucsText.slice(0, renderedText.length - 1);
        }
      }

      if (_nextTxt !== renderedText) {
        setRenderedText(_nextTxt);
      }
    }, TYPE_SPEED);

    return () => {
      clearInterval(interval);
    };
  }, [sequence, renderedText]);

  return (
    <p className="font-black text-[32px] min-h-[40px] leading-[40px] lg:text-[48px] lg:leading-[56px] lg:min-h-[56px]">
      {renderedText}
      {/* <Cursor /> */}
    </p>
  );
};

export default AnimateTyping;
