const LeftArrow = ({ onClick, ref, className = "", grey }) => {
  if (grey) {
    return (
      <div
        onClick={onClick}
        ref={ref}
        className={`${className} flex-none circle bg-[#06060608]`}
      >
        <img
          src="/images/include/left.png"
          className="w-auto h-[12px] object-contain"
        />
      </div>
    );
  }

  return (
    <div
      onClick={onClick}
      ref={ref}
      className={`${className} flex-none circle bg-[#fff]`}
    >
      <img
        alt="left"
        src="/images/include/left.png"
        className="w-auto h-[12px] object-contain"
      />
    </div>
  );
};

export default LeftArrow;
