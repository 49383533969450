import { I18nContext } from "components/I18N";
import Link from "next/link";
import { useContext } from "react";

function CategoryContent() {
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);

  return (
    <div className="container-wrapper my-[10px] lg:my-[60px] flex flex-col space-y-[80px] lg:space-y-[160px]">
      <div className="w-full row-mobile-col">
        <div className="flex-1">
          <div className="w-full lg:w-auto h-full rounded-[16px] overflow-hidden">
            <img
              alt="home content"
              src="/images/homepage/treat Sexual health-hibro.webp"
              className="zoom-hover"
            />
          </div>
        </div>
        <div className="flex-1 px-[24px] lg:px-[64px] py-[32px] lg:py-0 flex flex-col justify-center">
          <h3 className="heading6 font-black">
            {I18n.get("homePageSexualHealth")}
          </h3>
          <h4
            className="title mt-[8px] lg:mt-[16px]"
            dangerouslySetInnerHTML={{
              __html: I18n.get("homePageSexualHealthDesc"),
            }}
          />
          <div className="mt-[32px] lg:mt-[48px]">
            <Link href="/erectile-dysfunction">
              <div className="row-between cursor-pointer pb-[16px] lg:pb-[24px] border-b border-[##DCDCDC]">
                <p className="title font-bold mt-[8px] lg:mt-[16px]">
                  {I18n.get("homePageSexualItem1")}
                </p>
                <img
                  alt="home content"
                  src="/images/include/chevron-right.png"
                  className="h-[12px] w-auto"
                />
              </div>
            </Link>
            <Link href="/premature-ejaculation">
              <div className="row-between cursor-pointer pt-[16px] lg:pt-[24px]">
                <p className="title font-bold mt-[8px] lg:mt-[16px]">
                  {I18n.get("homePageSexualItem2")}
                </p>
                <img
                  alt="home content"
                  src="/images/include/chevron-right.png"
                  className="h-[12px] w-auto"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full row-mobile-col-reverse">
        <div className="flex-1 px-[24px] lg:px-[64px] py-[32px] lg:py-0 flex flex-col justify-center">
          <h3 className="heading6 font-black font-[CentraNo2]">
            {I18n.get("homePageHair")}
          </h3>
          <h4
            className="title mt-[8px] lg:mt-[16px]"
            dangerouslySetInnerHTML={{ __html: I18n.get("homePageHairDesc") }}
          />
          <div className="mt-[32px] lg:mt-[48px]">
            <Link href="/hair-loss">
              <div className="row-between cursor-pointer pb-[16px] lg:pb-[24px] border-b border-[##DCDCDC]">
                <p className="title font-bold mt-[8px] lg:mt-[16px]">
                  {I18n.get("homePageHairItem")}
                </p>
                <img
                  alt="home content"
                  src="/images/include/chevron-right.png"
                  className="h-[12px] w-auto"
                />
              </div>
            </Link>
          </div>
        </div>
        <div className="flex-1 w-full lg:w-auto h-full object-cover rounded-[16px] overflow-hidden">
          <img
            alt="home content"
            src="/images/homepage/hair loss - hibro.webp"
            className="zoom-hover"
          />
        </div>
      </div>
    </div>
  );
}

export default CategoryContent;
