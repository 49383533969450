import { SET_MODAL } from "./type";

export const setModal = (content) => async (dispatch) => {
  dispatch({
    type: SET_MODAL,
    payload: {
      ...content,
    },
  });
};
