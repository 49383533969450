import { useState, useContext, useEffect } from "react";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Modal from "react-modal";
import { I18nContext } from "../I18N";
import { useGetPhoneOrders } from "../../hooks/useGetPhoneOrders";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    background: "#00000060",
    transform: "translate(-50%, -50%)",
    padding: 0,
  },
};

Modal.setAppElement("#__next");

export const ReturningUserJourney = ({ auth }) => {
  //hooks
  const router = useRouter();
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);
  const { getPhoneOrders, getEDPhoneOrders, getPEPhoneOrders } =
    useGetPhoneOrders(auth);

  //states
  const [onOpenModel, setOpenModel] = useState();
  const [phoneOrders, setPhoneOrders] = useState([]);

  const isSessionStarted = sessionStorage.getItem("sessionStart");
  const isUserAuthenticated = !!get(auth, "user", false);
  const isShowModel = onOpenModel && !isSessionStarted && isUserAuthenticated;

  const edPhoneOrders = getEDPhoneOrders(phoneOrders) ?? [];
  const latestEDPhoneOrders = edPhoneOrders[0];
  const isCompletedEDOrders = latestEDPhoneOrders?.status === "COMPLETED";

  const pePhoneOrders = getPEPhoneOrders(phoneOrders) ?? [];
  const latestPEPhoneOrders = pePhoneOrders[0];
  const isCompletedPEOrders = latestPEPhoneOrders?.status === "COMPLETED";

  const doctorIDs = phoneOrders?.map((order) => order?.doctorID) ?? [];
  const isDoctorAssigned = doctorIDs.some((id) => id !== null);

  //effects
  useEffect(() => {
    setTimeout(() => {
      sessionStorage.setItem("sessionStart", true);
    }, "5000");

    const fetchPhoneOrders = async () => {
      const result = await getPhoneOrders(100);
      setPhoneOrders(result);
      setOpenModel(true);
    };

    fetchPhoneOrders();
  }, []);

  return (
    <Modal
      isOpen={isShowModel}
      style={customStyles}
      onRequestClose={() => {
        setOpenModel(false);
      }}
      overlayClassName="modal-overlay"
    >
      <div className="z-50 w-screen h-screen flex items-center justify-center">
        <div
          className={`${langCode}-font w-[80%] sm:w-[370px] md:w-[450px] lg:w-[560px] rounded-[16px] p-[36px] mx-auto flex flex-col text-thai bg-[#3B3F2F]`}
        >
          <div className="flex mb-[8px]">
            <p className="flex-auto font-[CentraNo2] font-extrabold text-white text-[24px] sm:text-[24px] md:text-[24px] lg:text-[32px]">
              Welcome back, bro
            </p>
            <button
              className="btn text-white"
              onClick={() => setOpenModel(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div>
            <p className="text-white mb-[40px] text-[14px] sm:text-[14px] md:text-[14px] lg:text-[16px]">
              {I18n.get("homePopupDescription")}
            </p>
            <div
              className="flex mb-[10px] cursor-pointer"
              onClick={() => {
                router.push(`/profile`);
              }}
            >
              <p className="flex-auto font-bold mb-[8px] text-white text-[16px] sm:text-[16px] md:text-[16px] lg:text-[20px]">
                {I18n.get("homePopupGoToTreatment")}
              </p>
              <ArrowForwardIosIcon
                sx={{ fontSize: "12px", color: "white", mt: "3px" }}
              />
            </div>
            <Divider sx={{ bgcolor: "gray" }} />
            {isCompletedEDOrders && (
              <>
                <div
                  className="flex mt-[20px] mb-[10px] cursor-pointer"
                  onClick={() => {
                    router.push(
                      `/phone-consult-complete?id=${latestEDPhoneOrders?.id}`
                    );
                  }}
                >
                  <p className="flex-auto font-bold mb-[8px] text-white text-[16px] sm:text-[16px] md:text-[16px] lg:text-[20px]">
                    {I18n.get("homePopupViewDiagnosisED")}
                  </p>
                  <ArrowForwardIosIcon
                    sx={{ fontSize: "12px", color: "white", mt: "3px" }}
                  />
                </div>
                <Divider sx={{ bgcolor: "gray" }} />
              </>
            )}
            {isCompletedPEOrders && (
              <>
                <div
                  className="flex mt-[20px] mb-[10px] cursor-pointer"
                  onClick={() => {
                    router.push(
                      `/phone-consult-complete?id=${latestPEPhoneOrders?.id}`
                    );
                  }}
                >
                  <p className="flex-auto font-bold mb-[8px] text-white text-[16px] sm:text-[16px] md:text-[16px] lg:text-[20px]">
                    {I18n.get("homePopupViewDiagnosisPE")}
                  </p>
                  <ArrowForwardIosIcon
                    sx={{ fontSize: "12px", color: "white", mt: "3px" }}
                  />
                </div>
                <Divider sx={{ bgcolor: "gray" }} />
              </>
            )}
            {isDoctorAssigned && (
              <>
                <div
                  className="flex mt-[20px] mb-[10px] cursor-pointer"
                  onClick={() => {
                    router.push(`/profile/chat`);
                  }}
                >
                  <p className="flex-auto font-bold mb-[8px] text-white text-[16px] sm:text-[16px] md:text-[16px] lg:text-[20px]">
                    {I18n.get("homePopupGoToChat")}
                  </p>
                  <ArrowForwardIosIcon
                    sx={{ fontSize: "12px", color: "white", mt: "3px" }}
                  />
                </div>
                <Divider sx={{ bgcolor: "gray" }} />
              </>
            )}
            <div
              className="flex mt-[20px] mb-[10px] cursor-pointer "
              onClick={() => {
                router.push(`/treatment`);
              }}
            >
              <p className="flex-auto font-bold mb-[8px] text-white text-[16px] sm:text-[16px] md:text-[16px] lg:text-[20px]">
                {I18n.get("homePopupBuyAgain")}
              </p>
              <ArrowForwardIosIcon
                sx={{ fontSize: "12px", color: "white", mt: "3px" }}
              />
            </div>
            <Divider sx={{ bgcolor: "gray" }} />
          </div>
          <div className="bg-[#F7F5F1] flex row-between w-[100%] h-[214px] lg:h-[268px] rounded-[12px] mt-[40px]">
            <div className="ml-[20px] min-w-[50%] sm:w-[60%] lg:w-[65%]">
              <p className="title font-bold mb-[18px] text-[16px] sm:text-[16px] md:text-[16px] lg:text-[24px]">
                {I18n.get("erectileDysfunction")}
              </p>
              <div
                className="flex space-x-[10px] cursor-pointer"
                onClick={() => {
                  router.push(`/erectile-dysfunction`);
                }}
              >
                <p className="subtitle whitespace-nowrap text-[12px] sm:text-[12px] md:text-[12px] lg:text-[16px]">
                  {I18n.get("conRead")}
                </p>
                <ArrowForwardIcon sx={{ fontSize: "14px", my: "auto" }} />
              </div>
            </div>
            <div>
              <img
                alt="home list"
                src="/images/products/ed.png"
                className="h-[129px] lg:h-[188px] w-auto object-cover object-left"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ReturningUserJourney);
