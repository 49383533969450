const RightArrow = ({ onClick, ref, className = "" }) => {
  return (
    <div
      onClick={onClick}
      ref={ref}
      className={`${className} flex-none circle bg-[#060606]`}
    >
      <img
        alt="right"
        src="/images/include/right.png"
        className="w-auto h-[12px] object-contain"
      />
    </div>
  );
};

export default RightArrow;
